import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isLoggedInUser } from '../../../contexts/User/helpers/userContextHelpers';
import { hasCoupons } from '@wix/ambassador-challenges-v1-challenge/http';
import { request } from '../../../services/request';

export const coupons = async (flowAPI: ControllerFlowAPI) => {
  if (!isLoggedInUser(flowAPI)) {
    return { hasCouponsForPrograms: false };
  }
  const resp = await request({
    flowAPI,
    options: hasCoupons({}),
    errorOptions: {
      errorCodesMap: {
        applicationError: {},
      },
    },
  });
  return {
    hasCouponsForPrograms: resp.data.result,
  };
};
