import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getMockedChallenge } from '../../../main/getMockedChallenges';
import { request } from '../../../../services/request';
import { DescriptionFieldSet } from '@wix/ambassador-challenges-v1-challenge/types';
import { Section } from '../../../../types/v3Types';
import {
  getSection,
  listSections as listSectionsV3,
} from '@wix/ambassador-online-programs-v3-section/http';
import { type GetSectionRequest } from '@wix/ambassador-online-programs-v3-section/types';

export default async function requestChallengeSections(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
  limit?: number,
): Promise<Section[]> {
  if (limit === 0) {
    return [];
  }
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }
  const sectionsResponse = await request({
    flowAPI,
    options: listSectionsV3({
      programId: slugOrChallengeId,
    }),
    errorOptions: {
      errorCodesMap: {
        applicationError: {},
      },
    },
  });
  return sectionsResponse?.data?.sections;
}

export async function requestSectionV3(
  { sectionId }: GetSectionRequest,
  flowAPI: ControllerFlowAPI,
) {
  return (
    await request({
      flowAPI,
      options: getSection({
        sectionId,
        descriptionFieldSet: DescriptionFieldSet.EXTENDED,
      }),
      errorOptions: {
        errorCodesMap: {
          applicationError: {},
        },
      },
    })
  )?.data?.section;
}
